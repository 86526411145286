import React, { useState } from "react";
import logo from "../logo.png";
import { Collapse, Menu } from "antd";
import type { MenuProps } from 'antd';
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Header.css';
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

// 检测缓存中是否有对当前页面语言进行配置：localStorage - language - hk: 繁体中文，cn: 简体中文，en: 英文
if (!localStorage.language) {
  localStorage.language = 'hk';
}


// 请求获取顶部导航栏列表
const language = localStorage.language;

const languageSelectTraditional = () => {
  localStorage.language = 'hk';
  window.location.reload();
}

const languageSelectSimplified = () => {
  localStorage.language = 'cn';
  window.location.reload();
}

const languageSelectEnglish = () => {
  localStorage.language = 'en';
  window.location.reload();
}

let topNavItems: MenuProps["items"] = [
  {
    label: (
      <a href={"/index"}>{language === 'hk' ? '主頁' : (language === 'en' ? 'Home' : '主页')}</a>
    ),
    key: 'index'
  },
  {
    label: `${language === 'hk' ? '關於大唐黃金' : (language === 'en' ? 'About GT Gold' : '关于大唐黄金')}`,
    key: 'about',
    children: [
      {
        label: (
          <a href={"/ChairmanMessage"}>{language === 'hk' ? '主席的話' : (language === 'en' ? `Chairman's Message` : '主席的话')}</a>
        ),
        key: 'ChairmanMessage'
      },
      {
        label: (
          <a href={"/DirectorsAndSeniorMangement"}>{language === 'hk' ? '董事及管理團隊' : (language === 'en' ? 'Directors and Senior Management' : '董事及管理团队')}</a>
        ),
        key: 'DirectorsAndSeniorMangement'
      },
      {
        label: (
          <a href={"/MissionVisionCorporateValues"}>{language === 'hk' ? '使命、願景及企業價值' : (language === 'en' ? 'Mission, Vision & Corporate Values' : '使命、愿景及企业价值')}</a>
        ),
        key: 'MissionVisionCorporateValues'
      },
    ]
  },
  {
    label: `${language === 'hk' ? '集團業務' : (language === 'en' ? 'Our Business' : '集团业务')}`,
    key: 'business',
    children: [
      {
        label: (
          <a href={"/LocationOfOurGoldMine"}>{language === 'hk' ? '金礦位置' : (language === 'en' ? 'Location Of Our Gold Mine' : '金矿位置')}</a>
        ),
        key: 'LocationOfOurGoldMine'
      },
      {
        label: (
          <a href={"/ReservesAndResources"}>{language === 'hk' ? '儲量及資源' : (language === 'en' ? 'Reserves And Resources' : '储量及资源')}</a>
        ),
        key: 'ReservesAndResources'
      },
      {
        label: (
          <a href={"/OurOperations"}>{language === 'hk' ? '營運生產' : (language === 'en' ? 'Our Operations' : '运营生产')}</a>
        ),
        key: 'OurOperations'
      },
      {
        label: (
          <a href={"/OurMinesDevelopment"}>{language === 'hk' ? '礦山開採及發展' : (language === 'en' ? 'Our Mines Development' : '矿山开采及发展')}</a>
        ),
        key: 'OurMinesDevelopment'
      },
      {
        label: (
          <a href={"/OurProcessingPlants"}>{language === 'hk' ? '選礦廠' : (language === 'en' ? 'Our Processing Plants' : '选矿厂')}</a>
        ),
        key: 'OurProcessingPlants'
      },
      {
        label: (
          <a href={"/Refinery"}>{language === 'hk' ? '冶煉廠' : (language === 'en' ? 'Refinery' : '冶炼厂')}</a>
        ),
        key: 'Refinery'
      },
      {
        label: (
          <a href={"/DiversifiedDevelopment"}>{language === 'hk' ? '多元化發展' : (language === 'en' ? 'Diversified Development' : '多元化发展')}</a>
        ),
        key: 'DiversifiedDevelopment'
      },
    ]
  },
  {
    label: `${language === 'hk' ? '投資者關係' : (language === 'en' ? 'Investor Relations' : '投资者关系')}`,
    key: 'InvestorRelations',
    children: [
      {
        label: (
          <a href={"/FinancialReports"}>{language === 'hk' ? '財務報告' : (language === 'en' ? 'Financial Company' : '财务报告')}</a>
        ),
        key: 'FinancialReports'
      },
      {
        label: (
          <a href={"/CompanyAnnouncements"}>{language === 'hk' ? '公司公告' : (language === 'en' ? 'Company Announcements' : '公司公告')}</a>
        ),
        key: 'CompanyAnnouncements'
      },
      {
        label: (
          <a href={"/Communication"}>{language === 'hk' ? '公司通訊發佈' : (language === 'en' ? 'Dissemination of Corporate Communications' : '公司通讯发布')}</a>
        ),
        key: 'CompanyAnnouncements'
      },
    ]
  },
  {
    label: `${language === 'hk' ? '環境保護' : (language === 'en' ? 'Environmental Protection' : '环境保护')}`,
    key: 'EnvironmentalProtection',
    children: [
      {
        label: (
          <a href={"/EnvironmentalandEcosystemProtection"}>{language === 'hk' ? '環境保護及生態修復' : (language === 'en' ? 'Environmental Protection and Ecological Rehabilitation' : '环境保护及生态修复')}</a>
        ),
        key: 'EnvironmentalandEcosystemProtection'
      },
      // {
      //     label: (
      //         <a href={"/MineRehabilitation"}>{language === 'hk' ? '礦山修復' : (language === 'en' ? 'Mine Rehabilitation' : '矿山修复')}</a>
      //     ),
      //     key: 'MineRehabilitation'
      // },
    ]
  },
  {
    label: (
      <a href={"/ContactUs"}>{language === 'hk' ? '聯絡我們' : (language === 'en' ? 'Contact Us' : '联络我们')}</a>
    ),
    key: 'ContactUs'
  },
  {
    label: (
      <a href={"/Links"}>{language === 'hk' ? '鏈接' : (language === 'en' ? 'Links' : '链接')}</a>
    ),
    key: 'Links'
  },
  {
    label: (
      <button className="css-1bkeob3">
        <div className="btn-inner">
          <div style={{ position: 'relative', width: '1.2em', height: '1.2em' }}>
            <span style={{ position: 'absolute', fontSize: '1.2em', lineHeight: '1', border: '1px solid rgba(0, 0, 0, 0.88)', color: '#fff', left: '-5%', top: '0', zIndex: '1', background: 'rgba(0, 0, 0, 0.88)', transformOrigin: '0 0', transform: 'scale(0.7)' }}>中</span><span
              style={{ position: 'absolute', fontSize: '1.2em', lineHeight: '1', border: '1px solid rgba(0, 0, 0, 0.88)', color: 'rgba(0, 0, 0, 0.88)', right: '-5%', bottom: 0, zIndex: 0, transformOrigin: '100% 100%', transform: 'scale(0.5)' }}>En</span>
          </div>
        </div>
      </button>
    ),
    key: 'Language',
    children: [
      {
        label: (
          <a onClick={languageSelectTraditional}>繁體中文</a>
        ),
        key: 'traditional'
      },
      {
        label: (
          <a onClick={languageSelectSimplified}>简体中文</a>
        ),
        key: 'simplified'
      },
      {
        label: (
          <a onClick={languageSelectEnglish}>English</a>
        ),
        key: 'english'
      },
    ]
  }
];

// if(localStorage.language === 'en') {
//     (document.getElementsByClassName('menu')[0] as HTMLElement).style.width = '84%';
// }

// const topNavItems:MenuProps["items"] = []
// 请求获取顶部导航栏列表 结束
function Header() {
  const [current, setCurrent] = useState('mail');

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const { Panel } = Collapse;
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const blockMobileMenu = () => {
    const mobileMenuIsBlock = document.querySelector('.mobileSelectMenu');
    const mobileMenuButtonIsBlock = document.querySelector('.mobileMenuBlock');
    const mobileMenuButtonIsNone = document.querySelector('.mobileMenuNone');
    // @ts-ignore
    Object.assign(mobileMenuIsBlock.style, {
      display: 'block'
    })
    // @ts-ignore
    Object.assign(mobileMenuButtonIsBlock.style, {
      display: 'none'
    })
    // @ts-ignore
    Object.assign(mobileMenuButtonIsNone.style, {
      display: 'block'
    })
  }

  const noneMobileMenu = () => {
    const mobileMenuIsBlock = document.querySelector('.mobileSelectMenu');
    const mobileMenuButtonIsBlock = document.querySelector('.mobileMenuBlock');
    const mobileMenuButtonIsNone = document.querySelector('.mobileMenuNone');
    // @ts-ignore
    Object.assign(mobileMenuIsBlock.style, {
      display: 'none'
    })
    // @ts-ignore
    Object.assign(mobileMenuButtonIsBlock.style, {
      display: 'block'
    })
    // @ts-ignore
    Object.assign(mobileMenuButtonIsNone.style, {
      display: 'none'
    })
  }


  return (
    <div className="header-container">
      <div className={"topNav"}>
        <img className={"logo"} src={logo} alt={"logo"} />
        <Menu className={`menu ${localStorage.language === 'en' ? 'specialElementStyle' : ''}`} style={{ width: localStorage.language === 'en' ? '83.5%' : '800px' }} selectable={true} onClick={onClick} selectedKeys={[current]} mode="horizontal"
          items={topNavItems} />
        <button onClick={blockMobileMenu} className={"mobileMenuBlock"}>
          <MenuOutlined />
        </button>
        <button onClick={noneMobileMenu} className={"mobileMenuNone"}>
          <CloseOutlined />
        </button>
      </div>
      <Collapse className={"mobileSelectMenu"} defaultActiveKey={['1']} onChange={onChange}>
        <a className={"amobile"} href={"/index"}><Panel style={{ background: "#fafafa", borderBottom: '1px solid #d9d9d9' }} className={"menuContent"} header={language === 'hk' ? '主頁' : (language === 'en' ? 'Home' : '主页')} key="1"></Panel></a>
        <Panel className={"menuContent"} header={language === 'hk' ? '關於大唐黃金' : (language === 'en' ? 'About GT Gold' : '关于大唐黄金')} key="2">
          <p className={"menuContent"}><a href={"/ChairmanMessage"}>{language === 'hk' ? '主席的話' : (language === 'en' ? `Chairman's Message` : '主席的话')}</a></p>
          <p className={"menuContent"}><a href={"/DirectorsAndSeniorMangement"}>{language === 'hk' ? '董事及管理團隊' : (language === 'en' ? 'Directors and Senior Management' : '董事及管理团队')}</a></p>
          <p className={"menuContent"}><a href={"/MissionVisionCorporateValues"}>{language === 'hk' ? '使命、願景及企業價值' : (language === 'en' ? 'Mission, Vision & Corporate Values' : '使命、愿景及企业价值')}</a></p>
        </Panel>
        <Panel className={"menuContent"} header={language === 'hk' ? '集團業務' : (language === 'en' ? 'Our Business' : '集团业务')} key="3">
          <p className={"menuContent"}><a href={"/LocationOfOurGoldMine"}>{language === 'hk' ? '金礦位置' : (language === 'en' ? 'Location Of Our Gold Mine' : '金矿位置')}</a></p>
          <p className={"menuContent"}><a href={"/ReservesAndResources"}>{language === 'hk' ? '儲量及資源' : (language === 'en' ? 'Reserves And Resources' : '储量及资源')}</a></p>
          <p className={"menuContent"}><a href={"/OurOperations"}>{language === 'hk' ? '營運生產' : (language === 'en' ? 'Our Operations' : '运营生产')}</a></p>
          <p className={"menuContent"}><a href={"/OurMinesDevelopment"}>{language === 'hk' ? '礦山開採及發展' : (language === 'en' ? 'Our Mines Development' : '矿山开采及发展')}</a></p>
          <p className={"menuContent"}><a href={"/OurProcessingPlants"}>{language === 'hk' ? '選礦廠' : (language === 'en' ? 'Our Processing Plants' : '选矿厂')}</a></p>
        </Panel>
        <Panel className={"menuContent"} header={language === 'hk' ? '投資者關係' : (language === 'en' ? 'Investor Relations' : '投资者关系')} key="4">
          <p className={"menuContent"}><a href={"/FinancialReports"}>{language === 'hk' ? '財務報告' : (language === 'en' ? 'Financial Company' : '财务报告')}</a></p>
          <p className={"menuContent"}><a href={"/CompanyAnnouncements"}>{language === 'hk' ? '公司公告' : (language === 'en' ? 'Company Announcements' : '公司公告')}</a></p>
          <p className={"menuContent"}><a href={"/Communication"}>{language === 'hk' ? '公司通訊發佈' : (language === 'en' ? 'Dissemination of Corporate Communications' : '公司通讯发布')}</a></p>
        </Panel>
        <Panel className={"menuContent"} header={language === 'hk' ? '環境保護' : (language === 'en' ? 'Environmental Protection' : '环境保护')} key="5">
          <p className={"menuContent"}><a href={"/EnvironmentalandEcosystemProtection"}>{language === 'hk' ? '環境生態保護' : (language === 'en' ? 'Environmental and Ecosystem Protection' : '环境生态保护')}</a></p>
          <p className={"menuContent"}><a href={"/MineRehabilitation"}>{language === 'hk' ? '礦山修復' : (language === 'en' ? 'Mine Rehabilitation' : '矿山修复')}</a></p>
        </Panel>
        <a className={"amobile"} href={"/ContactUs"}><Panel style={{ background: "#fafafa", borderBottom: '1px solid #d9d9d9' }} className={"menuContent"} header={language === 'hk' ? '聯絡我們' : (language === 'en' ? 'Contact Us' : '联络我们')} key="6"></Panel></a>
        <a className={"amobile"} href={"/Links"}><Panel style={{ background: "#fafafa", borderBottom: '1px solid #d9d9d9' }} className={"menuContent"} header={language === 'hk' ? '鏈接' : (language === 'en' ? 'Links' : '链接')} key="7"></Panel></a>
        <a className={"amobile"} onClick={languageSelectTraditional}><Panel style={{ background: "#fafafa", borderBottom: '1px solid #d9d9d9' }} className={"menuContent"} header="繁體中文" key="8"></Panel></a>
        <a className={"amobile"} onClick={languageSelectSimplified}><Panel style={{ background: "#fafafa", borderBottom: '1px solid #d9d9d9' }} className={"menuContent"} header="简体中文" key="9"></Panel></a>
        <a className={"amobile"} onClick={languageSelectEnglish}><Panel style={{ background: "#fafafa" }} className={"menuContent"} header="English" key="10"></Panel></a>
      </Collapse>
    </div>
  );
}

export default Header;
