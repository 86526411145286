import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Plants.css';
import '../public.css';
import axios from "axios";




let contentType = '';
if(localStorage.language === 'hk') {
    contentType = 'PlantMiddlePictureHK';
} else if (localStorage.language === 'cn') {
    contentType = 'PlantMiddlePictureCN';
} else if (localStorage.language === 'en') {
    contentType = 'PlantMiddlePictureEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('Processing')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('Processing')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('Processing')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PlantBottomDescHK';
} else if (localStorage.language === 'cn') {
    contentType = 'PlantBottomDescCN';
} else if (localStorage.language === 'en') {
    contentType = 'PlantBottomDescEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomDesc')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomDesc')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomDesc')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PLANTSTOP1HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PLANTSTOP1CN';
} else if (localStorage.language === 'en') {
    contentType = 'PLANTSTOP1EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('plantTitle1')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('plantContent1')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('plantTitle1')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('plantContent1')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('plantTitle1')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('plantContent1')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PLANTSTOP2HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PLANTSTOP2CN';
} else if (localStorage.language === 'en') {
    contentType = 'PLANTSTOP2EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('plantTitle2')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('plantContent2')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('plantTitle2')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('plantContent2')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('plantTitle2')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('plantContent2')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PLANTSTOP3HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PLANTSTOP3CN';
} else if (localStorage.language === 'en') {
    contentType = 'PLANTSTOP3EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('plantTitle3')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('plantContent3')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `

        const plant3Height = document.getElementsByClassName('plantContent3')[0].clientHeight;
        console.warn(`${plant3Height}px`)
        // @ts-ignore
        document.getElementsByClassName('plantContent1')[0].style.height = plant3Height;
        // @ts-ignore
        document.getElementsByClassName('plantContent2')[0].style.height = plant3Height;





    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('plantTitle3')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('plantContent3')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `

            const plant3Height = document.getElementsByClassName('plantContent3')[0].clientHeight;
            console.warn(`${plant3Height}px`)
                // @ts-ignore
                document.getElementsByClassName('plantContent1')[0].style.height = plant3Height;
                // @ts-ignore
                document.getElementsByClassName('plantContent2')[0].style.height = plant3Height;

    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('plantTitle3')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('plantContent3')[0].innerHTML += `
              ${res.data[0].article_english}
            `

            const plant3Height = document.getElementsByClassName('plantContent3')[0].clientHeight;
            console.warn(`${plant3Height}px`)

                // @ts-ignore
                document.getElementsByClassName('plantContent1')[0].style.height = plant3Height;
                // @ts-ignore
                document.getElementsByClassName('plantContent2')[0].style.height = plant3Height;


    }
})

if(localStorage.language === 'hk') {
    contentType = 'PlantBottom1HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PlantBottom1CN';
} else if (localStorage.language === 'en') {
    contentType = 'PlantBottom1EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomtitle1')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('bottomcontent1')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomtitle1')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('bottomcontent1')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomtitle1')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('bottomcontent1')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PlantBottom2HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PlantBottom2CN';
} else if (localStorage.language === 'en') {
    contentType = 'PlantBottom2EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomtitle2')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('bottomcontent2')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomtitle2')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('bottomcontent2')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomtitle2')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('bottomcontent2')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PlantBottom3HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PlantBottom3CN';
} else if (localStorage.language === 'en') {
    contentType = 'PlantBottom3EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomtitle3')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('bottomcontent3')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomtitle3')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('bottomcontent3')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomtitle3')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('bottomcontent3')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'PlantBottom4HK';
} else if (localStorage.language === 'cn') {
    contentType = 'PlantBottom4CN';
} else if (localStorage.language === 'en') {
    contentType = 'PlantBottom4EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomtitle4')[0].innerHTML += `
              ${res.data[0].title_traditional}
            `
        document.getElementsByClassName('bottomcontent4')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomtitle4')[0].innerHTML += `
              ${res.data[0].title_simplified}
            `
        document.getElementsByClassName('bottomcontent4')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomtitle4')[0].innerHTML += `
              ${res.data[0].title_english}
            `
        document.getElementsByClassName('bottomcontent4')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})


function Plants () {

    return (
        <div className="plantsContainer">
            <div className="navBottomBanner">
                <img src={"/bannerPlants.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '選礦廠' : (localStorage.language === 'en' ? 'OUR PROCESSING PLANTS' : '选矿厂')}</h1>
            </div>


            <div className="plantsContent">
                <div className="" style={{height: '40px'}}></div>
                <div className="plantsTopList">
                    <li className="plantsList">
                        <p className="listtitle plantTitle1"></p>
                        <div className="plantContent1 plantContent"></div>
                    </li>
                    <li className="plantsList">
                        <p className="listtitle plantTitle2"></p>
                        <div className="plantContent2 plantContent"></div>
                    </li>
                    <li className="plantsList">
                        <p className="listtitle plantTitle3"></p>
                        <div className="plantContent3 plantContent"></div>
                    </li>
                    <div style={{clear: 'both'}}></div>
                </div>

                <img className={"xuankuang"} src={localStorage.language === 'hk' ? '/xuankuang_fanti.png' : (localStorage.language === 'en' ? 'xuankuang_eng.png' : 'xuankuang_jianti.png')} />

                <div className={"middlePic"}>
                    <img src={"/plants/factory01.jpg"}/>
                    <img style={{marginLeft: '5px'}} src={"/plants/factory02.jpg"}/>
                    <div style={{clear: 'both'}}></div>
                </div>
                <div style={{ textAlign: 'center' }} id="Processing" className="Processing">
                    <p>{localStorage.language === 'hk' ? '礦石加工流程圖' : (localStorage.language === 'en' ? 'Ore Processing Plant' : '矿石加工流程图')}</p>
                </div>
            </div>
            <div style={{clear: 'both'}}></div>
            <div className="bottomDesc"></div>
            <div className="plantsBottomList">
                <li className="blist">
                    <p className="listtitle bottomtitle1"></p>
                    <div className="bottomcontent1 bottomcontent"></div>
                </li>
                <li className="blist">
                    <p className="listtitle bottomtitle2"></p>
                    <div className="bottomcontent2 bottomcontent"></div>
                </li>
                <li className="blist">
                    <p className="listtitle bottomtitle3"></p>
                    <div className="bottomcontent3 bottomcontent"></div>
                </li>
                <li className="blist">
                    <p className="listtitle bottomtitle4"></p>
                    <div className="bottomcontent4 bottomcontent"></div>
                </li>
                <div style={{clear: 'both'}}></div>
            </div>

            <div className="xuankuangchang_bottom_pic">
                <div className="bmpic1 bmpic">
                    <img src={'/xuankuangchang_bottom_1.jpg'}/>
                    <p>{localStorage.language === 'hk' ? '礦區' : (localStorage.language === 'en' ? 'mining area' : '矿区')}</p>
                </div>
                <div className="bmpic2 bmpic">
                    <img style={{ /* maxHeight: '488px', position: 'absolute', right: 0 */ }} src={'/xuankuangchang_bottom_2.jpg'}/>
                    <p>{localStorage.language === 'hk' ? '選礦區' : (localStorage.language === 'en' ? 'Mineral dressing area' : '选矿区')}</p>
                </div>
            </div>
        </div>
    );


}
export default Plants;
