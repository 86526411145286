import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Management.css';
import '../public.css';
import axios from "axios";

import 'bootstrap/dist/js/bootstrap';

if(window.location.pathname === '/DirectorsAndSeniorMangement') {
    require('bootstrap/dist/css/bootstrap.min.css');
}




let contentType = '';
if(localStorage.language === 'hk') {
    contentType = 'TraditionalDSMBanner';
} else if (localStorage.language === 'cn') {
    contentType = 'SimplifiedDSMBanner';
} else if (localStorage.language === 'en') {
    contentType = 'EnglishDSMBanner';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('managementPhoto')[0].innerHTML += `
            ${res.data[0].article_traditional}
          `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('managementPhoto')[0].innerHTML += `
            ${res.data[0].article_simplified}
          `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('managementPhoto')[0].innerHTML += `
            ${res.data[0].article_english}
          `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'TraditionalLiDahong';
} else if (localStorage.language === 'cn') {
    contentType = 'SimplifiedLiDahong';
} else if (localStorage.language === 'en') {
    contentType = 'EnglishLiDahong';
}
axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingOne">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                     aria-expanded="true" aria-controls="collapseOne">${res.data[0].title_traditional}</a></h4>
    </div>
    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <p>${res.data[0].article_traditional}</p>
      </div>
    </div>
  </div>
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingOne">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                     aria-expanded="true" aria-controls="collapseOne">${res.data[0].title_simplified}</a></h4>
    </div>
    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <p>${res.data[0].article_simplified}</p>
      </div>
    </div>
  </div>
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingOne">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                     aria-expanded="true" aria-controls="collapseOne">${res.data[0].title_english}</a></h4>
    </div>
    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <p>${res.data[0].article_english}</p>
      </div>
    </div>
  </div>
            `
    }
})
setTimeout(() => {
    if(localStorage.language === 'hk') {
        contentType = 'MaXiaonaHK';
    } else if (localStorage.language === 'cn') {
        contentType = 'MaXiaonaCN';
    } else if (localStorage.language === 'en') {
        contentType = 'MaXiaonaEN';
    }
    axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
        if(localStorage.language === 'hk') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingTwo">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                                     aria-expanded="true" aria-controls="collapseTwo">${res.data[0].title_traditional}</a></h4>
    </div>
    <div id="collapseTwo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
      <div class="panel-body">
        <p>${res.data[0].article_traditional}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'cn') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingTwo">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                                     aria-expanded="true" aria-controls="collapseTwo">${res.data[0].title_simplified}</a></h4>
    </div>
    <div id="collapseTwo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
      <div class="panel-body">
        <p>${res.data[0].article_simplified}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'en') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingTwo">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                                     aria-expanded="true" aria-controls="collapseTwo">${res.data[0].title_english}</a></h4>
    </div>
    <div id="collapseTwo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
      <div class="panel-body">
        <p>${res.data[0].article_english}</p>
      </div>
    </div>
  </div>
            `
        }
    })

    if(localStorage.language === 'hk') {
        contentType = 'GuoWeiHK';
    } else if (localStorage.language === 'cn') {
        contentType = 'GuoWeiCN';
    } else if (localStorage.language === 'en') {
        contentType = 'GuoWeiEN';
    }
    axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
        if(localStorage.language === 'hk') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingThree">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                                     aria-expanded="true" aria-controls="collapseThree">${res.data[0].title_traditional}</a></h4>
    </div>
    <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThree">
      <div class="panel-body">
        <p>${res.data[0].article_traditional}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'cn') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingThree">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                                     aria-expanded="true" aria-controls="collapseThree">${res.data[0].title_simplified}</a></h4>
    </div>
    <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThree">
      <div class="panel-body">
        <p>${res.data[0].article_simplified}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'en') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingThree">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                                     aria-expanded="true" aria-controls="collapseThree">${res.data[0].title_english}</a></h4>
    </div>
    <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThree">
      <div class="panel-body">
        <p>${res.data[0].article_english}</p>
      </div>
    </div>
  </div>
            `
        }
    })

    if(localStorage.language === 'hk') {
        contentType = 'LamAlbertManSumHK';
    } else if (localStorage.language === 'cn') {
        contentType = 'LamAlbertManSumCN';
    } else if (localStorage.language === 'en') {
        contentType = 'LamAlbertManSumEN';
    }
    axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
        if(localStorage.language === 'hk') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingFour">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                                     aria-expanded="true" aria-controls="collapseFour">${res.data[0].title_traditional}</a></h4>
    </div>
    <div id="collapseFour" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFour">
      <div class="panel-body">
        <p>${res.data[0].article_traditional}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'cn') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingFour">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                                     aria-expanded="true" aria-controls="collapseFour">${res.data[0].title_simplified}</a></h4>
    </div>
    <div id="collapseFour" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFour">
      <div class="panel-body">
        <p>${res.data[0].article_simplified}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'en') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingFour">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                                     aria-expanded="true" aria-controls="collapseFour">${res.data[0].title_english}</a></h4>
    </div>
    <div id="collapseFour" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFour">
      <div class="panel-body">
        <p>${res.data[0].article_english}</p>
      </div>
    </div>
  </div>
            `
        }
    })

    if(localStorage.language === 'hk') {
        contentType = 'CheungWaiHungHK';
    } else if (localStorage.language === 'cn') {
        contentType = 'CheungWaiHungCN';
    } else if (localStorage.language === 'en') {
        contentType = 'CheungWaiHungEN';
    }
    axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
        if(localStorage.language === 'hk') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingFive">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                                     aria-expanded="true" aria-controls="collapseFive">${res.data[0].title_traditional}</a></h4>
    </div>
    <div id="collapseFive" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFive">
      <div class="panel-body">
        <p>${res.data[0].article_traditional}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'cn') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingFive">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                                     aria-expanded="true" aria-controls="collapseFive">${res.data[0].title_simplified}</a></h4>
    </div>
    <div id="collapseFive" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFive">
      <div class="panel-body">
        <p>${res.data[0].article_simplified}</p>
      </div>
    </div>
  </div>
            `
        } else if (localStorage.language === 'en') {
            document.getElementsByClassName('panel-group')[0].innerHTML += `
              <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="headingFive">
      <h4 class="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                                     aria-expanded="true" aria-controls="collapseFive">${res.data[0].title_english}</a></h4>
    </div>
    <div id="collapseFive" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFive">
      <div class="panel-body">
        <p>${res.data[0].article_english}</p>
      </div>
    </div>
  </div>
            `
        }
    })
}, 200)


function Management () {
    return (
        <div className="managementContainer">
            <div className="navBottomBanner">
                <img src={"/bannerLinks.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '有遠見的管理團隊' : (localStorage.language === 'en' ? 'Visionary, management Team' : '有远见的管理团队')}</h1>
            </div>

            <div id="managementPhoto" className={"managementPhoto"} style={{display: 'table', margin: '30px auto'}}>
                <div className="managePhoto1">
                    <img src="/management_pic_1.jpg" alt=""/>
                    <p>{localStorage.language === 'hk' ? '核心管理團隊' : (localStorage.language === 'en' ? 'Core Management Team' : '核心管理团队')}</p>
                </div>
                <div style={{width: '30px', display: 'list-item'}}></div>
                <div className="managePhoto2">
                    <img src="/management_pic_2.jpg" alt=""/>
                    <p>{localStorage.language === 'hk' ? '國際合作' : (localStorage.language === 'en' ? 'Global Cooperation' : '国际合作')}</p>
                </div>
            </div>
            <div style={{clear: 'both'}}></div>
            <div className="manageList">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Management;
