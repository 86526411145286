import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import '../public.css';
import '../EnvironmentalandEcosystemProtection/Env.css';



export default function Refinery () {
    return(
        <div className="EnvContainer">
            <div className="navBottomBanner">
                <img src={"/bannerLinks.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '冶煉廠' : (localStorage.language === 'en' ? 'Refinery' : '冶炼厂')}</h1>
            </div>

            <div className="EnvContent">
                <p>
                    {
                        localStorage.language === 'hk'?
                            '太洲冶煉廠處理品位為50克/噸以上的金精粉，達產時具有產能300噸/噸，可產金錠4.5噸，品位99-99.9%。同時可產銀錠及銅精粉、鉛精粉等共生產品。' :
                            (localStorage.language === 'en'?
                                    'Grand TG Gold’s subsidiary Taizhou Mining has a refinery facility for refine gold concentrates with a grade of 50 gram/ton or above and a capacity up to 300 ton/day at its full operation capacity. When in full production, the facility is able to produce up to 4.5 tons of gold bars up to 99.99% gold content, as well as silver bars, and copper and lead concentrates. ':
                                    '太洲冶炼厂处理品位为50克/吨以上的金精粉，达产时具有产能300吨/吨，可产金锭4.5吨，品位99-99.9%。 同时可产银锭及铜精粉、铅精粉等共生产品。'
                            )
                    }
                </p>
                <p>
                    {
                        localStorage.language === 'hk'?
                            '太洲冶煉廠是在傳統冶煉工藝基礎上通過引進新的技術及工藝，大幅度提高回收率，降低運營週期，提高運營效率，為同類設備及工藝中的佼佼者。' :
                            (localStorage.language === 'en'?
                                    'Taizhou refinery facility has undergone extended technical renovation based on the conventional process, greatly enhances recovery rates, lower the production circle and improve the production efficiency. It is among the best of similar facilities. ':
                                    '太洲冶炼厂是在传统冶炼工艺基础上通过引进新的技术及工艺，大幅度提高回收率，降低运营周期，提高运营效率，为同类设备及工艺中的佼佼者。'
                            )
                    }
                </p>
                <p>
                    {
                        localStorage.language === 'hk'?
                            '太洲冶煉廠將通過進一步技術改造，提取其它共生礦金屬，進一步提高其收益及盈利能力。' :
                            (localStorage.language === 'en'?
                                    'Taizhou plans to further invest technical renovation to produce other metals, and further improve its profiting ability.   ':
                                    '太洲冶炼厂将通过进一步技术改造，提取其它共生矿金属，进一步提高其收益及盈利能力。'
                            )
                    }
                </p>
                {/*   配图 start   */}
                <img style={{width: '100%', height: 'auto'}} src={'/yl1.jpg'}/>
                <div>

                    <img src={'/yl2.jpg'}/>
                    <img src={'/yl3.jpg'}/>
                </div>
                {/*   配图 end   */}
            </div>
        </div>
    );
}
