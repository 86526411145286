import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Vision.css';
import '../public.css';
import axios from "axios";



let contentType = '';
if(localStorage.language === 'hk') {
    contentType = 'MISSIONHK';
} else if (localStorage.language === 'cn') {
    contentType = 'MISSIONCN';
} else if (localStorage.language === 'en') {
    contentType = 'MISSIONEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('missions')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_traditional}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_traditional}
                </p>
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('missions')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_simplified}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_simplified}
                </p>
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('missions')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_english}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_english}
                </p>
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'VisionHK';
} else if (localStorage.language === 'cn') {
    contentType = 'VisionCN';
} else if (localStorage.language === 'en') {
    contentType = 'VisionEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('visions')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_traditional}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_traditional}
                </p>
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('visions')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_simplified}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_simplified}
                </p>
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('visions')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_english}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_english}
                </p>
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'CorporateValueHK';
} else if (localStorage.language === 'cn') {
    contentType = 'CorporateValueCN';
} else if (localStorage.language === 'en') {
    contentType = 'CorporateValueEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('values')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_traditional}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_traditional}
                </p>
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('values')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_simplified}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_simplified}
                </p>
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('values')[0].innerHTML = `
                <p class="titleFont">
                   ${res.data[0].title_english}
                </p>
                <p class="titleContent">
                    ${res.data[0].article_english}
                </p>
            `
    }
})

function Vision () {
    return(
        <div className="visionContainer">
            <div className="navBottomBanner">
                <img src={"/MissionVisionCorporateValues.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '使命、願景和企業價值' : (localStorage.language === 'en' ? 'Mission, Vision & Corporate Values' : '使命、愿景及企业价值')}</h1>
            </div>

            <div className="visionContent">
                <div className="vision-container">
                    <div className="mission labelClass">
                        <img src="/mission.png" />
                        <div className="labelTitle missions">

                        </div>
                    </div>
                    <div className="vision labelClass">
                        <img src="/vision.png" />
                        <div className="labelTitle visions">

                        </div>
                    </div>
                    <div className="value labelClass">
                        <img src="/coprvalue.png" />
                        <div className="labelTitle values">

                        </div>
                    </div>
                    {/*<div style={{clear: 'both'}}></div>*/}
                </div>
            </div>
        </div>
    );
}
export default Vision;
