import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Resource.css';
import '../public.css';
import axios from "axios";



let contentType = '';
if(localStorage.language === 'hk') {
    contentType = 'ResourceFirstGreyBoxHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceFirstGreyBoxCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceFirstGreyBoxEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('FristGreyBox')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('FristGreyBox')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('FristGreyBox')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceSecondGreyBoxHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceSecondGreyBoxCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceSecondGreyBoxEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('SecondGreyBox')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('SecondGreyBox')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('SecondGreyBox')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceThirdGrayBoxHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceThirdGrayBoxCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceThirdGrayBoxEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('TrirdGreyBox')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('TrirdGreyBox')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('TrirdGreyBox')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceFirstNumberHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceFirstNumberCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceFirstNumberEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('FirstNumber')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
        document.getElementsByClassName('oneNumber')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('FirstNumber')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
        document.getElementsByClassName('oneNumber')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('FirstNumber')[0].innerHTML += `
              ${res.data[0].article_english}
            `
        document.getElementsByClassName('oneNumber')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceSecondNumberHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceSecondNumberCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceSecondNumberEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('SecondNumber')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
        document.getElementsByClassName('twoNumber')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('SecondNumber')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
        document.getElementsByClassName('twoNumber')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('SecondNumber')[0].innerHTML += `
              ${res.data[0].article_english}
            `
        document.getElementsByClassName('twoNumber')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceThirdNumberHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceThirdNumberCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceThirdNumberEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('ThirdNumber')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
        document.getElementsByClassName('threeNumber')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('ThirdNumber')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
        document.getElementsByClassName('threeNumber')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('ThirdNumber')[0].innerHTML += `
              ${res.data[0].article_english}
            `
        document.getElementsByClassName('threeNumber')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceMiddlePictureHK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceMiddlePictureCN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceMiddlePictureEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('childContent')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('childContent')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('childContent')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceBottonFontDesc1HK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceBottonFontDesc1CN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceBottonFontDesc1EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomFontDesc1')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomFontDesc1')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomFontDesc1')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'ResourceBottonFontDesc2HK';
} else if (localStorage.language === 'cn') {
    contentType = 'ResourceBottonFontDesc2CN';
} else if (localStorage.language === 'en') {
    contentType = 'ResourceBottonFontDesc2EN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('bottomFontDesc2')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('bottomFontDesc2')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('bottomFontDesc2')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

function Resource () {
    // @ts-ignore
    return(
        <div className="resourceContainer">
            <div className="navBottomBanner">
                <img src={"/bannerLinks.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '儲量及資源' : (localStorage.language === 'en' ? 'Reserves and Resources' : '储量及资源')}</h1>
            </div>

            <div className={"resourceContent"}>
                <div style={{width: '100%', height: '40px'}}></div>
                <div className="topContent">
                    <div className="topbg">
                        <span className="topbggrey FristGreyBox"></span>
                        <span className={"topbggreynumber"}>
                            <span>{localStorage.language === 'hk' ? '所含金屬：(千克，黃金)' : (localStorage.language === 'en' ? 'Contained Metal：(kilogram, gold)' : '所含金属：(千克，黄金)')}</span>
                            <span className={"oneNumber"}></span>
                        </span>
                        <span className="topbggrey SecondGreyBox" style={{ marginLeft: '20px', marginRight: '20px' }}></span>
                        <span className={"topbggreynumber"}>
                            <span>{localStorage.language === 'hk' ? '所含金屬：(千克，黃金)' : (localStorage.language === 'en' ? 'Contained Metal：(kilogram, gold)' : '所含金属：(千克，黄金)')}</span>
                            <span className={"twoNumber"}></span>
                        </span>
                        <span className="topbggrey TrirdGreyBox"></span>
                        <span className={"topbggreynumber"}>
                            <span>{localStorage.language === 'hk' ? '所含金屬：(千克，黃金)' : (localStorage.language === 'en' ? 'Contained Metal：(kilogram, gold)' : '所含金属：(千克，黄金)')}</span>
                            <span className={"threeNumber"}></span>
                        </span>
                    </div>
                    <div className="yellowBottom" style={{width: '100%', height: '80px', backgroundColor: '#E0AF3A'}}>
                        <div className="yellowButtonNumber">
                            <span className="FirstNumber">
                                <img src="/resource/resourceBottom.png"/>
                            </span>
                            <span className="SecondNumber" style={{marginLeft: '11%', marginRight: '11%'}}>
                                <img src="/resource/resourceBottom.png"/>
                            </span>
                            <span className="ThirdNumber">
                                <img src="/resource/resourceBottom.png"/>
                            </span>
                        </div>
                        <div className="yellowBgFont" style={{fontWeight: 'bold', color: '#fff', marginBottom: '20px', paddingLeft: '20px'}}>
                            <p>{localStorage.language === 'hk' ? '所含金屬：' : (localStorage.language === 'en' ? 'Contained Metal：' : '所含金属：')}</p>
                            <p>{localStorage.language === 'hk' ? '(千克，黃金)' : (localStorage.language === 'en' ? '(kilogram, gold)' : '(千克，黄金)')}</p>
                        </div>
                    </div>
                    <div className="fontDesc">
                        <p>{localStorage.language === 'hk' ? '^於2022年3月31日' : (localStorage.language === 'en' ? '^As at 31 March 2022' : '^于2022年3月31日')}</p>
                        <p>{localStorage.language === 'hk' ? '#以1.0克╱噸(金)邊界品位計算' : (localStorage.language === 'en' ? '#Based on a cut-off grade of 1.0g/t Au' : '#以1.0克╱吨(金)边界品位计算')}</p>
                        <p>{localStorage.language === 'hk' ? '*以1.9克╱噸(金)邊界品位計算' : (localStorage.language === 'en' ? '*Based on a cut-off grade of 1.9g/t Au' : '*以1.9克╱吨(金)边界品位计算')}</p>
                    </div>
                </div>
            </div>
            <div className="childContent" id="childContent">
            </div>
            <div style={{clear: 'both'}}></div>
            <div className="fontdes">
                <h1>{localStorage.language === 'hk' ? '附註' : (localStorage.language === 'en' ? 'Remarks' : '附注')}</h1>
                <div style={{paddingTop: '40px'}}>
                    <p className="num">1</p>
                    <span className="bottomFontDesc1"></span>
                    <p className="num">2</p>
                    <span className="bottomFontDesc2"></span>
                    <div style={{clear: 'both'}}></div>
                </div>
            </div>

            <div className="bottomThreePic">
                <img src={"/resource/6.jpg"}/>
                <img className={"bottomMiddlePic"} src={"/resource/5.jpg"}/>
                <img src={"/resource/3.jpg"}/>
            </div>

        </div>
    );
}
export default Resource;
