import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import '../public.css';
import '../EnvironmentalandEcosystemProtection/Env.css';



export default function DiverDev() {
  return (
    <div className="EnvContainer">
      <div className="navBottomBanner">
        <img src={"/EnvironmentalandEcosystemProtection.jpg"} />
        <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '多元化發展' : (localStorage.language === 'en' ? 'Diversified Development' : '多元化发展')}</h1>
      </div>

      <div className="EnvContent EnvContentBody">
        <p className={"dContentTitle"}>
          {localStorage.language === 'hk' ? '國際合作' : (localStorage.language === 'en' ? 'International Cooperation' : '国际合作')}
        </p>
        <p>
          {
            localStorage.language === 'hk' ?
              '大唐黃金管理團隊有著豐富的國際投融資經驗，曾投資澳洲金礦並且一貫關注南非、澳洲等的金礦資產。在實際合適時，將開展跨境併購業務，進一步落實資產多元化的發展戰略。' :
              (localStorage.language === 'en' ?
                'Grand TG Gold management team has extensive experience with international investment and financing in the mining sector. Grand TG Gold has invested in gold mines in Australia, and has been closely evaluating gold mining assets in South Africa, Australia, etc. At the appropriate moments, Grand TG Gold would conduct cross border acquisition again, further implement its diversified development strategy.' :
                '大唐黄金管理团队有着丰富的国际投融资经验，曾投资澳洲金矿并且一贯关注南非、澳大利亚等的金矿资产。在实际合适时，将开展跨境并购业务，进一步落实资产多元化的发展战略。 '
              )
          }
        </p>


        <p>
          {
            localStorage.language === 'hk' ?
              '大唐黃金積極參與國內、國際貿易，包括金錠進口，金精粉、沙石料進口等國際貿易。大唐黃金具有相當海外資源及業務關係，為國際貿易的基礎。' :
              (localStorage.language === 'en' ?
                'Grand TG Gold actively involves in domestic and international trades, including imports of gold bars, gold concentrates, and sand and gravel materials. Grand TG has a wide network of international business contacts, laying down the basis for international trade. ' :
                '大唐黄金积极参与国内、国际贸易，包括金锭进口，金精粉、沙石料进口等国际贸易。大唐黄金具有相当海外资源及业务关系，为国际贸易的基础。'
              )
          }
        </p>
        <img src={"/g1.jpg"} />
        <img src={'/g2.jpg'} />
        <img src={"/g3.jpg"} />
        <img src={'/g4.jpg'} />
        <img src={"/g5.jpg"} />
        <img src={'/g6.jpg'} />
        <p className={"dContentTitle"}>
          {localStorage.language === 'hk' ? '尾礦綜合利用' : (localStorage.language === 'en' ? 'Tailing Re-utilization' : '尾矿综合利用')}
        </p>
        <p>
          {
            localStorage.language === 'hk' ?
              '大唐黃金與其它央企合作，充分利用礦山資源，開展尾礦製磚，金精粉、沙石窩料物流業務、礦山多元化經營包括礦山地質公園等工業旅遊項目。' :
              (localStorage.language === 'en' ?
                'Grand TG Gold cooperate with central enterprises, utilizing the resources related to tailing materials for bricks, gold concentrates and sand and gravel materials logistic business and abandoned mine conversion such as Ming Geology Park, as industrial tourism projects.' :
                '大唐黄金与其它央企合作，充分利用矿山资源，开展尾矿制砖，金精粉、沙石窝料物流业务、矿山多元化经营包括矿山地质公园等工业旅游项目。'
              )
          }
        </p>
        {/*   配图 start   */}
        <div>
          <img src={'/c1.jpg'} />
          <img src={"/c2.jpg"} />
          <img src={'/d4.jpg'} />
          <img src={"/d1.jpg"} />
        </div>
        {/*   配图 end   */}
      </div>
    </div>
  );
}

