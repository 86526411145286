import React, {useEffect, useState} from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Home.css';
import axios from "axios";
import {ColumnsType, TablePaginationConfig} from "antd/lib/table";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import {Carousel, Table, Tooltip} from "antd";
import {FilePdfOutlined} from "@ant-design/icons";
import { Swiper, SwiperSlide  } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Navigation } from "swiper";


// 2023年4月6日更新 start
let url = new URL(window.location.href);
const urlSearchParams = new URLSearchParams(url.search);
// 2023年4月6日更新 end


axios.get('https://qt.gtimg.cn/q=hk08299').then((res) => {
    const share = res.data.split('~');
    const name = `${share[1]} ${share[2]}`
    var elements1=res.data.split("~");
    var strh1= elements1[1];
    var curPrice1=elements1[3];
    var lastEndPrice1=elements1[4];
    var todayStartPrice1=elements1[5];
    var todayHigh1=elements1[33];
    var todaylow1=elements1[34];
    var buyprice1=elements1[3];
    var shopprice1=elements1[3];
    var shopcount1=elements1[29];
    var shopcountprice1=elements1[37];
    var subPrice1=elements1[31];
    var classStr1="";
    if(subPrice1>0){
        classStr1="up";
    }else if(subPrice1<0){
        classStr1="down";
    }
    document.getElementsByClassName("stockPrice")[0].innerHTML=curPrice1;
})

const hrefStockPrice = () => {
    if(navigator.language === 'zh-CN') {
        window.open('https://stock.finance.sina.com.cn/hkstock/quotes/08299.html');
    } else {
        window.open('https://finance.yahoo.com/quote/8299.HK/');
    }
}




axios.get(`https://systemapi.grandtg.com/sapi/getHomePageBannerFont?language=${localStorage.language}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('wordGroup')[0].innerHTML = res.data.title_traditional;
        document.getElementsByClassName('wordGroup')[0].innerHTML = res.data.article_traditional;
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('wordGroup')[0].innerHTML = res.data.title_simplified;
        document.getElementsByClassName('wordGroup')[0].innerHTML = res.data.article_simplified;
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('wordGroup')[0].innerHTML = res.data.title_english;
        document.getElementsByClassName('wordGroup')[0].innerHTML = res.data.article_english;
    }
})

axios.get(`https://systemapi.grandtg.com/sapi/getCompanyAnnouncements?language=${localStorage.language}`).then((res) => {
    if (localStorage.language === 'en') {
        for (let i = 0; i < 3; i++) {
                document.getElementsByClassName('topInvestorTitle')[0].innerHTML += `
              <li>
              <a style="color: #000" href="${res.data[i].article_english}">${res.data[i].title_english}</a>
              <p>${res.data[i].pushDate}</p>
              <a href="${res.data[i].article_english}"><svg t="1666790616089" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="32" height="32"><path d="M709.845333 250.346667a22.4 22.4 0 0 1 0.533334-30.848 20.48 20.48 0 0 1 29.717333 0.64l272.426667 292.693333-272.426667 292.650667a20.458667 20.458667 0 0 1-29.717333 0.64c-8.32-8.32-8.746667-21.973333-0.533334-30.848l242.346667-262.464-242.346667-262.464z" fill="#cea139" p-id="2522"></path></svg></a>
            </li>
              `
        }
    } else if (localStorage.language === 'cn') {
        for (let i = 0; i < 3; i++) {
                document.getElementsByClassName('topInvestorTitle')[0].innerHTML += `
                <li>
                <a style="color: #000" href="${res.data[i].article_simplified}">${res.data[i].title_simplified}</a>
                <p>${res.data[i].pushDate}</p>
                <a href="${res.data[i].article_simplified}"><svg t="1666790616089" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="32" height="32"><path d="M709.845333 250.346667a22.4 22.4 0 0 1 0.533334-30.848 20.48 20.48 0 0 1 29.717333 0.64l272.426667 292.693333-272.426667 292.650667a20.458667 20.458667 0 0 1-29.717333 0.64c-8.32-8.32-8.746667-21.973333-0.533334-30.848l242.346667-262.464-242.346667-262.464z" fill="#cea139" p-id="2522"></path></svg></a>
              </li>
                `
        }
    } else if (localStorage.language === 'hk') {
        for (let i = 0; i < 3; i++) {
                document.getElementsByClassName('topInvestorTitle')[0].innerHTML += `
              <li>
              <a style="color: #000" href="${res.data[i].article_traditional}">${res.data[i].title_traditional}</a>
              <p>${res.data[i].pushDate}</p>
              <a href="${res.data[i].article_traditional}"><svg t="1666790616089" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="32" height="32"><path d="M709.845333 250.346667a22.4 22.4 0 0 1 0.533334-30.848 20.48 20.48 0 0 1 29.717333 0.64l272.426667 292.693333-272.426667 292.650667a20.458667 20.458667 0 0 1-29.717333 0.64c-8.32-8.32-8.746667-21.973333-0.533334-30.848l242.346667-262.464-242.346667-262.464z" fill="#cea139" p-id="2522"></path></svg></a>
            </li>
              `
        }
    }
})

let contentType = '';
if (localStorage.language === 'hk') {
    contentType = 'news-hk';
} else if(localStorage.language === 'cn') {
    contentType = 'news-cn';
} else if (localStorage.language === 'en') {
    contentType = 'news-en';
}


axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'en') {
        for(let i = 0; i <= 5; i++) {
            if(i < 3) {
                document.getElementsByClassName('indexNewsList')[0].innerHTML += `
                <div class="newsList">
                  <div class="indexNewsListNewsPushDate">${res.data[i].pushDate}</div>
                  <div class="indexNewsListNewsTitle"><img class='star' src="/star.png"><a target="_blank" style="color: #000" href="${res.data[i].article_english}">${res.data[i].title_english}</a></div>
                </div>
                `
            } else {
                document.getElementsByClassName('indexNewsPDF')[0].innerHTML += `
                <div class="newsList">
                  <div class="indexNewsListNewsPushDate">${res.data[i].pushDate}</div>
                  <div class="indexNewsListNewsTitle"><img class='star' src="/star.png"><a target="_blank" style="color: #000" href="${res.data[i].article_english}">${res.data[i].title_english}</a></div>
                </div>
                `
            }

        }
    } else if(localStorage.language === 'cn') {
        for(let i = 0; i <= 5; i++) {
            if(i < 3) {
                document.getElementsByClassName('indexNewsList')[0].innerHTML += `
              <div class="newsList">
                <div class="indexNewsListNewsPushDate">${res.data[i].pushDate}</div>
                <div class="indexNewsListNewsTitle"><img class='star' src="/star.png"><a target="_blank" style="color: #000" href="${res.data[i].article_simplified}">${res.data[i].title_simplified}</a></div>
              </div>
              `
            } else {
                document.getElementsByClassName('indexNewsPDF')[0].innerHTML += `
              <div class="newsList">
                <div class="indexNewsListNewsPushDate">${res.data[i].pushDate}</div>
                <div class="indexNewsListNewsTitle"><img class='star' src="/star.png"><a target="_blank" style="color: #000" href="${res.data[i].article_simplified}">${res.data[i].title_simplified}</a></div>
              </div>
              `
            }

        }
    } else if(localStorage.language === 'hk') {
        for(let i = 0; i <= 5; i++) {
            if (i < 3) {
                document.getElementsByClassName('indexNewsList')[0].innerHTML += `
              <div class="newsList">
                <div class="indexNewsListNewsPushDate">${res.data[i].pushDate}</div>
                <div class="indexNewsListNewsTitle"><img class='star' src="/star.png"><a target="_blank" style="color: #000" href="${res.data[i].article_traditional}">${res.data[i].title_traditional}</a></div>
              </div>
              `
            } else {
                document.getElementsByClassName('indexNewsPDF')[0].innerHTML += `
              <div class="newsList">
                <div class="indexNewsListNewsPushDate">${res.data[i].pushDate}</div>
                <div class="indexNewsListNewsTitle"><img class='star' src="/star.png"><a target="_blank" style="color: #000" href="${res.data[i].article_traditional}">${res.data[i].title_traditional}</a></div>
              </div>
              `
            }

        }
    }
})


if(localStorage.language === 'hk') {
    contentType = 'TraditionalHomeAboutGroup';
} else if (localStorage.language === 'cn') {
    contentType = 'SimplifiedHomeAboutGroup';
} else if (localStorage.language === 'en') {
    contentType = 'EnglishHomeAboutGroup';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('aboutGroup')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('aboutGroup')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('aboutGroup')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

// const mySwiper = new Swiper('.roll', {
//     freeMode: true,
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//     },
// });

axios.get(`https://systemapi.grandtg.com/sapi/getScrollContent?language=${localStorage.language}`).then((res) => {

    for(let i in res.data) {
        if(localStorage.language === 'hk') {
            document.getElementsByClassName('swiper-wrapper')[0].innerHTML += `
                <div class="content swiper-slide">
                  <div class="titleContent" style=${res.data[i].article_traditional.indexOf('<img') !== -1 ? 'float:right;width:60%' : ''}>
                    <p class="scrollContentTitle">${res.data[i].title_traditional}</p>
                    <div class="scrollContentContent">${res.data[i].article_traditional}</div>
                  </div>
                </div>
              `
        } else if(localStorage.language === 'cn') {
            document.getElementsByClassName('swiper-wrapper')[0].innerHTML += `
                <div class="content swiper-slide">
                  <div class="titleContent" style=${res.data[i].article_simplified.indexOf('<img') !== -1 ? 'float:right;width:60%' : ''}>
                    <p class="scrollContentTitle">${res.data[i].title_simplified}</p>
                    <div class="scrollContentContent">${res.data[i].article_simplified}</div>
                  </div>
                </div>
              `
        } else if(localStorage.language === 'en') {
            document.getElementsByClassName('swiper-wrapper')[0].innerHTML += `
                <div class="content swiper-slide">
                  <div class="titleContent" style=${res.data[i].article_english.indexOf('<img') !== -1 ? 'float:right;width:60%' : ''}>
                    <p class="scrollContentTitle">${res.data[i].title_english}</p>
                    <div class="scrollContentContent">${res.data[i].article_english}</div>
                  </div>
                </div>
              `
        }


    }

})


function Home() {
    useEffect(() => {
        axios.get(`https://systemapi.grandtg.com/sapi/getHomeFontIntroduction?language=${localStorage.language}`).then((res) => {
            if(localStorage.language === 'hk') {
                document.getElementsByClassName('middleMessageTitle')[0].innerHTML = res.data.title_traditional;
                document.getElementsByClassName('middleMessageArtcle')[0].innerHTML = res.data.article_traditional;
            } else if (localStorage.language === 'cn') {
                document.getElementsByClassName('middleMessageTitle')[0].innerHTML = res.data.title_simplified;
                document.getElementsByClassName('middleMessageArtcle')[0].innerHTML = res.data.article_simplified;
            } else if (localStorage.language === 'en') {
                document.getElementsByClassName('middleMessageTitle')[0].innerHTML = res.data.title_english;
                document.getElementsByClassName('middleMessageArtcle')[0].innerHTML = res.data.article_english;
            }
        })
    })


    const gridStyle: React.CSSProperties = {
        width: '25%',
        textAlign: 'center',
    };

    interface DataType {
        key: string;
        title_simplified: string;
        title_traditional: string;
        title_english: string;
        article_simplified: string;
        article_traditional: string;
        article_english: string;
        type: string;
        author: string;
        pushDate: string;
        // tags: string[];
    }
    interface TableParams {
        pagination?: TablePaginationConfig;
        sortField?: string;
        sortOrder?: string;
        filters?: Record<string, FilterValue>;
    }

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 20,
            showSizeChanger: false
        },
    })

    const fetchData = () => {
        setLoading(true);
        axios.get(`https://systemapi.grandtg.com/sapi/getFinancialReports?language=${localStorage.language}`).then((res) => {
            console.log(res.data)
            // console.log(res.data.pushDate)
            setData(res.data);
            setLoading(false);
            setTableParams({
                ...tableParams,
            })
        })
    }

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (
        showSizeChanger: false,
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<DataType>,
    ) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    }
    //'Android' || 'webOS' || 'iPhone' || 'iPod' || 'BlackBerry'
    window.navigator.userAgent.toString().indexOf('Mobile') !== -1 ? console.log('手机端') : console.log('PC端')
    console.log(window.navigator.userAgent.toString())
    const columns: ColumnsType<DataType> = [
        {
            title: `${localStorage.language === 'hk' ? '標題' : (localStorage.language === 'en' ? 'File title' : '标题')}`,
            dataIndex: `${localStorage.language === 'hk' ? 'title_traditional' : (localStorage.language === 'en' ? 'title_english' : 'title_simplified')}`,
            key: `${localStorage.language === 'hk' ? 'title_traditional' : (localStorage.language === 'en' ? 'title_english' : 'title_simplified')}`,
            ellipsis: true,
            render: (value, record) => {
                return (
                    <div title={value} style={{ whiteSpace: 'nowrap', float: 'left', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Tooltip placement="topLeft" title={value}>
                            <a className={"article_title"} title={""} target="_blank" href={localStorage.language === 'hk' ? `${record.article_traditional}` : (localStorage.language === 'en' ? `${record.article_english}` : `${record.article_simplified}`)}>{value}</a>
                        </Tooltip>
                    </div>
                )
            }, // TODO 点击进入编辑界面
        },
        {
            title: `${localStorage.language === 'hk' ? '公開日期' : (localStorage.language === 'en' ? 'Publish Dates' : '公开日期')}`,
            dataIndex: 'pushDate',
            key: 'pushDate',
            // width: 90,
            width: window.navigator.userAgent.toString().indexOf('Mobile') !== -1 ? 90 : 120
        },
        {
            title: `${localStorage.language === 'hk' ? '下載' : (localStorage.language === 'en' ? 'Download' : '下载')}`,
            dataIndex: `${localStorage.language === 'hk' ? 'article_traditional' : (localStorage.language === 'en' ? 'article_english' : 'article_simplified')}`,
            key: `${localStorage.language === 'hk' ? 'article_traditional' : (localStorage.language === 'en' ? 'article_english' : 'article_simplified')}`,
            render: (value, record) => {
                return (
                    <a style={{ color: '#E0AF3A' }} href={value}>
                        <FilePdfOutlined />
                    </a>
                )
            },
            width: 60,
        }
    ];

    return(
        <div className="home-container">
            <div className="banner">
                <div className="bannerGruop">
                    {/*<img src="/banner.png"/>*/}
                    {/*<div className="wordGroup"></div>*/}
                    <Carousel autoplay effect={"fade"} dotPosition={"top"}>
                        <div>
                            <div style={{
                                maxHeight: '600px',
                                color: '#fff',
                                // lineHeight: '600px',
                                textAlign: 'center',
                                backgroundImage: '/banner1.jpeg',}}><img src={'/banner1.jpeg'}/>1</div>
                        </div>
                        <div>
                            <div style={{
                                maxHeight: '600px',
                                color: '#fff',
                                // lineHeight: '600px',
                                textAlign: 'center',
                                backgroundImage: '/banner2.jpeg',}}><img src={'/banner2.jpeg'}/>2</div>
                        </div>
                        <div>
                            <div style={{
                                maxHeight: '600px',
                                color: '#fff',
                                // lineHeight: '600px',
                                textAlign: 'center',
                                backgroundImage: '/banner3.jpeg',}}><img src={'/banner3.jpeg'}/>3</div>
                        </div>
                        {/*<div>*/}
                        {/*    <div style={{*/}
                        {/*        maxHeight: '600px',*/}
                        {/*        color: '#fff',*/}
                        {/*        // lineHeight: '600px',*/}
                        {/*        textAlign: 'center',*/}
                        {/*        backgroundImage: '/banner4.jpeg',}}><img src={'/banner4.jpeg'}/>4</div>*/}
                        {/*</div>*/}
                    </Carousel>
                </div>
                <div className="subtitle" style={urlSearchParams.has('subtitle') ? {display: "block"} : {display: "none"}}>
                    <ul className="topInvestorTitle" style={{width: '100%'}}>
                        <li style={{border: 'none', float: 'right'}}>
                            <p>HKEX</p>
                            <p><h1>HK $<span className="stockPrice"></span></h1></p>
                            <p>8299HK</p>
                            <a onClick={hrefStockPrice}>
                                <svg d="1666790616089" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="2521" width="32" height="32">
                                    <path
                                        d="M709.845333 250.346667a22.4 22.4 0 0 1 0.533334-30.848 20.48 20.48 0 0 1 29.717333 0.64l272.426667 292.693333-272.426667 292.650667a20.458667 20.458667 0 0 1-29.717333 0.64c-8.32-8.32-8.746667-21.973333-0.533334-30.848l242.346667-262.464-242.346667-262.464z"
                                        fill="#cea139" p-id="2522"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <Swiper
                style={urlSearchParams.has('dev') ? {display: "block"} : {display: "none"}}
                modules={[Navigation]}
                spaceBetween={50}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                navigation
                freeMode={true}
            >
            </Swiper>

            {/*<div className="roll swiper">*/}
            {/*    <div className="swiper-wrapper">*/}
            {/*    </div>*/}
            {/*    <div className="swiper-button-prev"></div>*/}
            {/*    <div className="swiper-button-next"></div>*/}
            {/*    <div style={{clear: 'both'}}></div>*/}
            {/*</div>*/}

            <div className="aboutGroup" style={{display: 'none'}}>

            </div>
            <div style={{clear: 'both'}}></div>

            <div className="middleMessage" style={urlSearchParams.has('dev') ? {} : {marginTop: "0"}}>
                <img style={urlSearchParams.has('dev') ? {display: "block"} : {display: "none"}} src="/middle.png"/>
                <div className="middleFontDesc">
                    <p className="middleMessageTitle"></p>
                    <div className="middleMessageArtcle">

                    </div>
                </div>
            </div>

            <div className="indexNews">
                <div className="indexNewsTitle">
                    <p style={{fontSize: '32px'}}>{localStorage.language === 'hk' ? '最新行業消息' : (localStorage.language === 'en' ? 'News' : '最新行业消息')}</p>
                    {/*<a className="indexNewsTitleViewAllNews" href="#">*/}
                    {/*    <p>View all news</p>*/}
                    {/*    <svg d="1666790616089" className="icon" viewBox="0 0 1024 1024" version="1.1"*/}
                    {/*         xmlns="http://www.w3.org/2000/svg" p-id="2521" width="24" height="24">*/}
                    {/*        <path*/}
                    {/*            d="M709.845333 250.346667a22.4 22.4 0 0 1 0.533334-30.848 20.48 20.48 0 0 1 29.717333 0.64l272.426667 292.693333-272.426667 292.650667a20.458667 20.458667 0 0 1-29.717333 0.64c-8.32-8.32-8.746667-21.973333-0.533334-30.848l242.346667-262.464-242.346667-262.464z"*/}
                    {/*            fill="#cea139" p-id="2522"></path>*/}
                    {/*    </svg>*/}
                    {/*</a>*/}
                </div>

                <div className="indexNewsList">

                </div>

                <div className="indexNewsPDF">

                </div>
                <div style={{clear: 'both'}}></div>
            </div>

            <div className="middleMessage" style={{display: 'none'}}>
                <img src="/bottom.png"/>
                <div className="middleFontDesc">
                    <p className="middleMessageTitle" style={{textAlign: 'left'}}>
                        {localStorage.language === 'hk' ? '財務報告' : (localStorage.language === 'en' ? 'FINANCIAL REPORTS' : '财务报告')}
                    </p>
                    <div className="middleMessageList">
                        <Table dataSource={data} columns={columns} />
                    </div>
                </div>
            </div>
        </div>
    );
    // const swiperChildren = document.getElementsByClassName('swiper-wrapper')[0];
    // for(let i in swiperChildren) {
    //     console.warn(swiperChildren);
    // }
}
export default Home;
