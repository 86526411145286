import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Links.css';
import '../public.css';



function Links() {
    return(
        <div className="link-container">
            <div className="navBottomBanner">
                <img className={"LinksBanner"} src={"/Links.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '鏈接' : (localStorage.language === 'en' ? 'LINKS' : '链接')}</h1>
            </div>

            <div className="link-content">
                <div className="linksContainer">
                    <p>{localStorage.language === 'hk' ? '❖中國黃金協會' : (localStorage.language === 'en' ? '❖China Gold Association' : '❖中国黄金协会')} <a target={"_blank"} href="https://www.cngold.org.cn/">https://www.cngold.org.cn/</a></p>
                    {/*<p>{localStorage.language === 'hk' ? '世界黃金協會' : (localStorage.language === 'en' ? 'World Gold Council' : '世界黄金协会')} <a target={"_blank"} href="http://www.gold.org/">http://www.gold.org/</a></p>*/}
                    <p>{localStorage.language === 'hk' ? '❖世界黃金協會' : (localStorage.language === 'en' ? '❖World Gold Council' : '❖世界黄金协会')} <a target={"_blank"} href="https://www.cngold.org.cn/">https://www.cngold.org.cn/</a></p>
                    <p>{localStorage.language === 'hk' ? '❖香港國際黃金協會' : (localStorage.language === 'en' ? '❖Hong Kong International Gold Council' : '❖香港国际黄金协会')} <a target={"_blank"} href="http://www.hkiga.com/cn/">http://www.hkiga.com/cn/</a></p>
                    <p>{localStorage.language === 'hk' ? '❖倫敦金銀市場協會' : (localStorage.language === 'en' ? '❖London Bullion Market Association' : '❖伦敦金银市场协')} <a target={"_blank"} href="http://www.lbma.org.uk/">http://www.lbma.org.uk</a></p>
                    <p>{localStorage.language === 'hk' ? '❖上海黃金交易所' : (localStorage.language === 'en' ? '❖Shanghai Gold Exchange' : '❖上海黄金交易所')} <a target={"_blank"} href="http://www.sge.sh/">http://www.sge.sh/</a></p>
                    <p>{localStorage.language === 'hk' ? '❖上海有色金屬網' : (localStorage.language === 'en' ? '❖Shanghai Nonferrous Metals Market Net' : '❖上海有色金属网')} <a target={"_blank"} href="http://smm.cn/">http://smm.cn</a></p>
                    <p>{localStorage.language === 'hk' ? '❖香港交易所' : (localStorage.language === 'en' ? '❖Stock Exchange of Hong Kong Limited' : '❖香港交易所')} <a target={"_blank"} href="http://www.hkex.com.hk/index.htm">http://www.hkex.com.hk/index.htm</a></p>
                    {/*<p>{localStorage.language === 'hk' ? '' : (localStorage.language === 'en' ? '' : '世界黄金协会')} <a target={"_blank"} href=""></a></p>*/}
                </div>
            </div>
        </div>
    );
}
export default Links;
