import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Operations.css';
import '../public.css';
import axios from "axios";




let contentType = '';
if(localStorage.language === 'hk') {
    contentType = 'OUROPERATIONSHK1';
} else if (localStorage.language === 'cn') {
    contentType = 'OUROPERATIONSCN1';
} else if (localStorage.language === 'en') {
    contentType = 'OUROPERATIONSEN1';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('title1')[0].innerHTML += `
              ${res.data[0].title_traditional}
        `
        document.getElementsByClassName('content1')[0].innerHTML += `
              ${res.data[0].article_traditional}
        `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('title1')[0].innerHTML += `
              ${res.data[0].title_simplified}
        `
        document.getElementsByClassName('content1')[0].innerHTML += `
              ${res.data[0].article_simplified}
        `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('title1')[0].innerHTML += `
              ${res.data[0].title_english}
        `
        document.getElementsByClassName('content1')[0].innerHTML += `
              ${res.data[0].article_english}
        `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'OUROPERATIONSHK2';
} else if (localStorage.language === 'cn') {
    contentType = 'OUROPERATIONSCN2';
} else if (localStorage.language === 'en') {
    contentType = 'OUROPERATIONSEN2';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('title2')[0].innerHTML += `
              ${res.data[0].title_traditional}
        `
        document.getElementsByClassName('content2')[0].innerHTML += `
              ${res.data[0].article_traditional}
        `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('title2')[0].innerHTML += `
              ${res.data[0].title_simplified}
        `
        document.getElementsByClassName('content2')[0].innerHTML += `
              ${res.data[0].article_simplified}
        `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('title2')[0].innerHTML += `
              ${res.data[0].title_english}
        `
        document.getElementsByClassName('content2')[0].innerHTML += `
              ${res.data[0].article_english}
        `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'OUROPERATIONSHK3';
} else if (localStorage.language === 'cn') {
    contentType = 'OUROPERATIONSCN3';
} else if (localStorage.language === 'en') {
    contentType = 'OUROPERATIONSEN3';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('title3')[0].innerHTML += `
              ${res.data[0].title_traditional}
        `
        document.getElementsByClassName('content3')[0].innerHTML += `
              ${res.data[0].article_traditional}
        `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('title3')[0].innerHTML += `
              ${res.data[0].title_simplified}
        `
        document.getElementsByClassName('content3')[0].innerHTML += `
              ${res.data[0].article_simplified}
        `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('title3')[0].innerHTML += `
              ${res.data[0].title_english}
        `
        document.getElementsByClassName('content3')[0].innerHTML += `
              ${res.data[0].article_english}
        `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'OUROPERATIONSHK4';
} else if (localStorage.language === 'cn') {
    contentType = 'OUROPERATIONSCN4';
} else if (localStorage.language === 'en') {
    contentType = 'OUROPERATIONSEN4';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('title4')[0].innerHTML += `
              ${res.data[0].title_traditional}
        `
        document.getElementsByClassName('content4')[0].innerHTML += `
              ${res.data[0].article_traditional}
        `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('title4')[0].innerHTML += `
              ${res.data[0].title_simplified}
        `
        document.getElementsByClassName('content4')[0].innerHTML += `
              ${res.data[0].article_simplified}
        `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('title4')[0].innerHTML += `
              ${res.data[0].title_english}
        `
        document.getElementsByClassName('content4')[0].innerHTML += `
              ${res.data[0].article_english}
        `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'OUROPERATIONSHK5';
} else if (localStorage.language === 'cn') {
    contentType = 'OUROPERATIONSCN5';
} else if (localStorage.language === 'en') {
    contentType = 'OUROPERATIONSEN5';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('title5')[0].innerHTML += `
              ${res.data[0].title_traditional}
        `
        document.getElementsByClassName('content5')[0].innerHTML += `
              ${res.data[0].article_traditional}
        `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('title5')[0].innerHTML += `
              ${res.data[0].title_simplified}
        `
        document.getElementsByClassName('content5')[0].innerHTML += `
              ${res.data[0].article_simplified}
        `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('title5')[0].innerHTML += `
              ${res.data[0].title_english}
        `
        document.getElementsByClassName('content5')[0].innerHTML += `
              ${res.data[0].article_english}
        `
    }
})

if(localStorage.language === 'hk') {
    contentType = 'OUROPERATIONSHK6';
} else if (localStorage.language === 'cn') {
    contentType = 'OUROPERATIONSCN6';
} else if (localStorage.language === 'en') {
    contentType = 'OUROPERATIONSEN6';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('title6')[0].innerHTML += `
              ${res.data[0].title_traditional}
        `
        document.getElementsByClassName('content6')[0].innerHTML += `
              ${res.data[0].article_traditional}
        `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('title6')[0].innerHTML += `
              ${res.data[0].title_simplified}
        `
        document.getElementsByClassName('content6')[0].innerHTML += `
              ${res.data[0].article_simplified}
        `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('title6')[0].innerHTML += `
              ${res.data[0].title_english}
        `
        document.getElementsByClassName('content6')[0].innerHTML += `
              ${res.data[0].article_english}
        `
    }
})

function Operations () {
    return (
        <div className="operationsContainer">
            <div className="navBottomBanner">
                <img src={"/bannerOperations1.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '營運生產' : (localStorage.language === 'en' ? 'OUR OPERATIONS' : '营运生产')}</h1>
            </div>

            <div className="operationsContent">
                <div className="opcontent"
                     style={{paddingTop: '50px', width: '80%', margin: '0 auto', display: 'block', minWidth: '1024px'}}>
                    <h2 className="sm-title">
                        {localStorage.language === 'hk' ? '水文地質學' : (localStorage.language === 'en' ? 'Hydrogeology' : '水文地质学')}
                    </h2>
                    <div className="topList">
                        <li>
                            <p className="title1" style={{float: 'left'}}></p>
                            <div className="contentSpan content1" style={{float: 'left'}}></div>
                        </li>
                        <li>
                            <p className="title2" style={{margin: '0 auto', display: 'block'}}></p>
                            <div className="contentSpan content2" style={{margin: '0 auto'}}></div>
                        </li>
                        <li>
                            <p className="title3" style={{float: 'right'}}></p>
                            <div className="contentSpan content3" style={{float: 'right'}}></div>
                        </li>
                        <div style={{clear: 'both'}}></div>
                    </div>
                    <h2 className="sm-title">
                        {localStorage.language === 'hk' ? '地質工程' : (localStorage.language === 'en' ? 'Geotechnical Engineering' : '地质工程')}
                    </h2>
                    <div className="bottomList">
                        <li className="bottomListLi">
                            <p className="title4" style={{float: 'left'}}></p>
                            <div style={{clear: 'both'}}></div>
                            <div className="content4" style={{float: 'left'}}>

                            </div>
                        </li>
                        <li className="bottomListLi">

                            <p className="title5" style={{margin: '0 auto'}}></p>
                            <div className="content5" style={{margin: '0 auto', display: 'block'}}>

                            </div>
                        </li>
                        <li className="bottomListLi">
                            <p className="title6" style={{float: 'right'}}></p>
                            <div style={{clear: 'both'}}></div>
                            <div className="content6" style={{float: 'right'}}>

                            </div>
                        </li>
                        <div style={{clear: 'both'}}></div>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
            </div>
            <div className={"bottomOperPic"}>
                <img src={"/5181680787357_.pic.jpg"}/>
                <img src={"/5191680787357_.pic.jpg"}/>
                <img src={"/28141694780893_.pic.jpg"}/>
                <img src={"/28171694780895_.pic.jpg"}/>
            </div>
        </div>
    );
}
export default Operations;
