import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Chairman.css';
import '../public.css';
import axios from "axios";




axios.get(`https://systemapi.grandtg.com/sapi/getChairmanMessage?language=${localStorage.language}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('chairmanFont')[0].innerHTML = res.data.article_traditional;
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('chairmanFont')[0].innerHTML = res.data.article_simplified;
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('chairmanFont')[0].innerHTML = res.data.article_english;
    }
})

function Chairman () {
    return (
        <div className="chairmanContainer">
            <div className="navBottomBanner">
                <img src={"/ChairmanMessage.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '有遠見的管理團隊' : (localStorage.language === 'en' ? 'Visionary, management Team' : '有远见的管理团队')}</h1>
            </div>

            <div className="chairmanContent">
                <p className="chairmanContentTitle">
                    {localStorage.language === 'hk' ? '主席的話' : (localStorage.language === 'en' ? `Chairman's Message` : '主席的话')}
                </p>
                {/*<div className="photo">*/}
                {/*    <img src="/chairman.png" />*/}
                {/*        <p>*/}
                {/*            {localStorage.language === 'hk' ? '集團主席：李大宏博士' : (localStorage.language === 'en' ? 'Group Chairman: Dr. Li Dahong' : '集团主席：李大宏博士')}*/}
                {/*        </p>*/}
                {/*</div>*/}
                <div className="chairmanFont">

                </div>
            </div>
        </div>
    );
}
export default Chairman;
