import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Communication.css';
import '../public.css';
import axios from "axios";




axios.get(`https://systemapi.grandtg.com/sapi/getCommunication?language=${localStorage.language}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('communicationFont')[0].innerHTML = res.data.article_traditional;
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('communicationFont')[0].innerHTML = res.data.article_simplified;
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('communicationFont')[0].innerHTML = res.data.article_english;
    }
})

function Chairman () {
    return (
        <div className="chairmanContainer">
            <div className="navBottomBanner">
                <img src={"/ChairmanMessage.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '公司通訊發佈' : (localStorage.language === 'en' ? `Dissemination of Corporate Communications` : '公司通讯发布')}</h1>
            </div>
            
            <div className="chairmanContent">
                {/*<div className="photo">*/}
                {/*    <img src="/chairman.png" />*/}
                {/*        <p>*/}
                {/*            {localStorage.language === 'hk' ? '集團主席：李大宏博士' : (localStorage.language === 'en' ? 'Group Chairman: Dr. Li Dahong' : '集团主席：李大宏博士')}*/}
                {/*        </p>*/}
                {/*</div>*/}
                <div className="communicationFont" >

                </div>
            </div>
        </div>
    );
}
export default Chairman;
