import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import '../public.css';
import './Env.css';



function Env() {
  return (
    <div className="EnvContainer">
      <div className="navBottomBanner envPic">
        <img src={"/EnvironmentalandEcosystemProtection.jpg"} />
        <h1 style={{ fontSize: "2vw" }} className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '環境保護及生態修復' : (localStorage.language === 'en' ? 'Environmental Protection and Ecological Rehabilitation' : '环境保护及生态修复')}</h1>
      </div>

      <div className="EnvContent">
        <p>
          {
            localStorage.language === 'hk' ?
              '大唐黃金高度認同並實踐生產與環保並重的理念，在開發生產黃金的每一程序中植入環境保護的措施，把對環境及周邊社區的影響降到最低。' :
              (localStorage.language === 'en' ?
                'Grand TG Gold highly supports the concept of concurrent industrial production and environmental protection, and embodies procedures to safeguard the surrounding environment and communities in every steps of its gold mining activities and minimize the adverse impacts.' :
                '大唐黄金高度认同并实践生产与环保并重的理念，在开发生产黄金的每一程序中植入环境保护的措施，把对环境及周边社区的影响降到最低。'
              )
          }
        </p>
        <p>
          {
            localStorage.language === 'hk' ?
              '大唐黃金與業界龍頭央企合作, 在下屬礦山企業及國內其它廢棄礦山地區策劃、設計、包裝示範生態修復工程，及山水林田湖草沙和廢棄礦山一體化修復、治理、業態轉型項目。' :
              (localStorage.language === 'en' ?
                'Grand TG Gold cooperates with leading central enterprises to plan, design and package ecological model projects in its mining areas and the abandoned mine areas for ecology rehabilitation, restoration and conversion in its uses, as well as the the “Mountains, rivers, forests, farmland, lakes grasslands and deserts” Initiative in other parts of the country.' :
                '大唐黄金与业界龙头央企合作, 在下属矿山企业及国内其它废弃矿山地区策划、设计、包装示范生态修复工程，及山水林田湖草沙和废弃矿山一体化修复、治理、业态转型项目。'
              )
          }
        </p>
        {/*   配图 start   */}
        <div className={"middlePic"}>
          <img src={'/k1.png'} />
          <img src={'/k2.png'} />
          <img src={'/k3.png'} />
          <img src={'/k4.png'} />
        </div>
        <img style={{ width: '100%', height: 'auto' }} src={'/k5.png'} />
        {/*   配图 end   */}
        <p>
          {
            localStorage.language === 'hk' ?
              '大唐黃金與央企及地方政府、礦山企業等密切配合，打造央地合作、中港合作模式典範，並建設礦山地區環境生態綜合修復、廢棄礦山業務轉型、經濟多元化發展，實踐總書記綠水青山就是金山銀山理念的示範項目。' :
              (localStorage.language === 'en' ?
                'Grand TG Gold works closely with central enterprises, local governments, mining companies, to build model cooperation between central and local governments and between China mainland and Hong Kong, to conduct mining area ecological rehabilitation, abandoned mine business conversion, diversified economic development, and implement the concept of “Green mountains and clear waters are mountains of gold and silver” from the Party General Secretary.' :
                '大唐黄金与央企及地方政府、矿山企业等密切配合，打造央地合作、中港合作模式典范，并建设矿山地区环境生态综合修复、废弃矿山业务转型、经济多元化发展，实践总书记绿水青山就是金山银山理念的示范项目。'
              )
          }
        </p>
        <img style={{ width: '100%', height: 'auto' }} src={'/yq1.jpg'} />
        {/*   配图 start   */}
        <div className={"renwu"}>

          <img src={'/yq2.jpg'} />
          <img src={'/yq3.jpg'} />
        </div>
        {/*   配图 end   */}
      </div>
    </div>
  );
}
export default Env;
