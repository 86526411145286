import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Development.css';
import '../public.css';



function Development () {
    return (
        <div className="developmentContainer">
            <div className="navBottomBanner">
                <img src={"/development/developmentBanner.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '礦山開採及發展' : (localStorage.language === 'en' ? 'OUR MINES DEVELOPMENT' : '矿山开采及发展')}</h1>
            </div>

            <div className="developContent">
                <p className={"caikuangzheng"}>
                    {localStorage.language === 'hk' ? '採礦證：附屬潼關縣太州礦業有限責任公司2022年採礦證更新時，大幅度增加了採礦面積，至8.2194平方公里，為陝西省近年來發放面積最大採礦證之一。根隨著國家礦權政策的調整，公司將繼續優化採礦權證，增強資源潛力和採礦能力。' : (localStorage.language === 'en' ? 'Mining Permit：when renewing its mining permit, subsidiary Tongguan Taizhou Mining Limited was granted one of the largest mining areas in recent years in Shaanxi Privice, at 8.2194 square kilometers. Along with the modification of the state mining rights policies, the Company is to continue optimize its mining rights to further increase its resources potential and mining abililty. ' : '采矿证：附属潼关县太州矿业有限责任公司2022年采矿证更新时，大幅度增加了采矿面积，至8.2194平方公里，为陕西省近年来发放面积最大采矿证之一。根随着国家矿权政策的调整，公司将继续优化采矿权证，增强资源潜力和采矿能力。')}
                </p>
                <img className="development" src={localStorage.language === 'hk' ? '/development/development_hk.png' : (localStorage.language === 'en' ? '/development/development_en.png' : '/development/development_cn.png')}/>
            </div>
            <div className="developChildContent">
                <div className="realWidth">
                    <ul className="devfontdes">
                        <li>
                            {localStorage.language === 'hk' ? '主入口設置在 +1,125m 高度，包括主通道 PD68' : (localStorage.language === 'en' ? 'The main portal is set at the +1,125m leveland includes the main adit, PD68' : '主入口设置在 +1,125m 高度，包括主通道 PD68')}
                        </li>
                        <li>
                            {localStorage.language === 'hk' ? '主入口長 2,000 米，其他尺寸為寬 2.2 米，高 2.0 米' : (localStorage.language === 'en' ? 'The main portal is 2,000m longwith other dimensions of 2.2m in width by 2.0m in height' : '主入口长 2,000 米，其他尺寸为宽 2.2 米，高 2.0 米')}
                        </li>
                        <li>
                            {localStorage.language === 'hk' ? 'C|16內燃機車和0.9mª軌道車採用12kg/m鋼軌' : (localStorage.language === 'en' ? 'A steel rail of 12kg/m weight is used for C|16 diesel powered locomotives and 0.9mª rail cars' : 'C|16内燃机车和0.9mª轨道车采用12kg/m钢轨')}
                        </li>
                        <li>
                            {localStorage.language === 'hk' ? '新開發的平渠設置在與舊平渠相同的水平面上。 新坑寬2.5m，高2.5m，用於增加產能' : (localStorage.language === 'en' ? 'A newly developed adit is set at the same level as the old one. The new adit has dimensions of 2.5m in widthby 2.5m in height,which is used for increasing production capacity' : '新开发的平渠设置在与旧平渠相同的水平面上。 新坑宽2.5m，高2.5m，用于增加产能')}
                        </li>
                        <li>
                            {localStorage.language === 'hk' ? '礦石和廢石也通過 PD68 平洞轉移到地表' : (localStorage.language === 'en' ? 'The ore and waste rocks are also transferred to the surface through the PD68 adit' : '矿石和废石也通过 PD68 平洞转移到地表')}
                        </li>
                    </ul>
                    <img style={{height: '400px', width: '40%'}} src="/28161694780895_.pic.jpg"/>
                    <div style={{clear: 'both'}}></div>
                </div>
            </div>

            {/*<div className="developmentBottomPic">*/}
            {/*    <div className="devPic1">*/}
            {/*        <img style={{maxHeight: '478px'}} src="/kuangshan_1.jpg" alt=""/>*/}
            {/*        <p>{localStorage.language === 'hk' ? '礦井' : (localStorage.language === 'en' ? 'mine' : '矿井')}</p>*/}
            {/*    </div>*/}
            {/*    <div style={{width: '30px', display: 'list-item'}}></div>*/}
            {/*    <div className="devPic2">*/}
            {/*        <img src={"/kuangshan_2.jpg"} />*/}
            {/*        <p>{localStorage.language === 'hk' ? '岩石/地質' : (localStorage.language === 'en' ? 'rock/geology' : '岩石/地质')}</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="developmentBottomPic devBottomPic bottomOperPic">
                <img style={{maxHeight: '478px'}} src={"/kuangshan_1.jpg"} alt=""/>
                <img src={"/development/bottomPic.png"}/>
                <img src={"/kuangshan_2.jpg"} />
                <img src={"/28151694780894_.pic.jpg"}/>
            </div>
        </div>
    );
}
export default Development;
