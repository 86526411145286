import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <span className={"copyright"}>copyright ©️ 2004 - {new Date().getFullYear()} {localStorage.language === 'hk' ? '大唐黃金控股有限公司 版權所有.' : (localStorage.language === 'en' ? 'GT Gold Holdings Limited All Rights Reserved.' : '大唐黄金控股有限公司 版权所有.')}</span>
    </div>
  );
}
export default Footer;
