import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './GoldLocation.css';
import '../public_gold.css';
import axios from "axios";



let contentType = '';
if(localStorage.language === 'hk') {
    contentType = 'LOCATIONGOLDHK';
} else if (localStorage.language === 'cn') {
    contentType = 'LOCATIONGOLDCN';
} else if (localStorage.language === 'en') {
    contentType = 'LOCATIONGOLDEN';
}

axios.get(`https://systemapi.grandtg.com/sapi/universalAPI?contentType=${contentType}`).then((res) => {
    if(localStorage.language === 'hk') {
        document.getElementsByClassName('goldLocationContent')[0].innerHTML += `
              ${res.data[0].article_traditional}
            `
    } else if (localStorage.language === 'cn') {
        document.getElementsByClassName('goldLocationContent')[0].innerHTML += `
              ${res.data[0].article_simplified}
            `
    } else if (localStorage.language === 'en') {
        document.getElementsByClassName('goldLocationContent')[0].innerHTML += `
              ${res.data[0].article_english}
            `
    }
})

function GoldLocation () {
    return(
      <div className="goldLocationContainer">
          <div className="navBottomBanner">
              <img src={"/ChairmanMessage.png"} />
              <h1 style={{ textAlign: 'center' }} className={"navBottomBannerFonts"}>
                  <p className={"firstLine"}>{localStorage.language === 'hk' ? `我們金礦的位置` : (localStorage.language === 'en' ? `LOCATION OF OUR GOLD MINE` : `我们金矿的位置`)}</p>
                  <p className={"secondLine"}>{localStorage.language === 'hk' ? `財富需要用汗水澆灌` : (localStorage.language === 'en' ? `wealth needs watering with sweat` : `财富需要汗水浇灌`)}</p>
              </h1>
          </div>

          <div className="goldLocationContent">

          </div>
          <div className={"bottomGoldPic"}>
              <img src={"/28581695729926_.pic.jpg"}/>
              <img className={"rightGoldPic"} src={"/5251680787394_.pic.jpg"}/>
          </div>
      </div>
    );
}
export default GoldLocation;
