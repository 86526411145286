import React from 'react';
import './App.css';
import {Layout} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import HeaderNav from './Header/Header';
import Home from './Home/Home';
import Links from './Links/Links';
import FooterContainer from './Footer/Footer';
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import './color.css';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import ContactUs from "./ContactUs/ContactUs";
import MineRehabilitation from "./MineRehabilitation/MineRehabilitation";
import Env from "./EnvironmentalandEcosystemProtection/Env";
import Reports from "./FinancialReports/Reports";
import Company from "./CompanyAnnouncements/Company";
import Chairman from "./Chairman/Chairman";
import Communication from "./Communication/Communication";
import Management from "./Management/Management";
import Vision from "./Vision/Vision";
import GoldLocation from "./GoldLocation/GoldLocation";
import Resource from "./Resource/Resource";
import Operations from "./Operations/Operations";
import Development from "./Development/Development";
import Plants from "./Plants/Plants";
import Refinery from "./Refinery/Refinery";
import DiverDev from "./DiversifiedDevelopment/DiverDev";

// 检测缓存中是否有对当前页面语言进行配置：localStorage - language - hk: 繁体中文，cn: 简体中文，en: 英文
if (!localStorage.language) {
    localStorage.language = 'hk';
}



function App() {
  return (
      <Layout>
          <Layout>
              <Header className={"Header"}>
                  <HeaderNav />
              </Header>
              <Content className={"Content"}>
                  <BrowserRouter>
                      <Routes>
                          <Route path={'/'} element={<Home />} />
                          <Route path={'/index'} element={<Home />} />
                          <Route path={'/Links'} element={<Links />} />
                          <Route path={'/ContactUs'} element={<ContactUs />} />
                          <Route path={'/MineRehabilitation'} element={<MineRehabilitation />} />
                          <Route path={'/EnvironmentalandEcosystemProtection'} element={<Env />} />
                          <Route path={'/FinancialReports'} element={<Reports />} />
                          <Route path={'/CompanyAnnouncements'} element={<Company />} />
                          <Route path={'/ChairmanMessage'} element={<Chairman />} />
                          <Route path={'/Communication'} element={<Communication />} />
                          <Route path={'/DirectorsAndSeniorMangement'} element={<Management />} />
                          <Route path={'/MissionVisionCorporateValues'} element={<Vision />} />
                          <Route path={'/LocationOfOurGoldMine'} element={<GoldLocation />} />
                          <Route path={'/ReservesAndResources'} element={<Resource />} />
                          <Route path={'/OurOperations'} element={<Operations />} />
                          <Route path={'/OurMinesDevelopment'} element={<Development />} />
                          <Route path={'/OurProcessingPlants'} element={<Plants />} />
                          <Route path={'/Refinery'} element={<Refinery />} />
                          <Route path={'/DiversifiedDevelopment'} element={<DiverDev />} />
                      </Routes>
                  </BrowserRouter>
              </Content>
              <Footer className={"Footer"}>
                  <FooterContainer />
              </Footer>
          </Layout>
      </Layout>
  );
}

export default App;
