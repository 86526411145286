import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import {Empty} from "antd";
import '../public.css';



function MineRehabilitation () {
    return(
        <div className="MineRehabilitationContainer">
            <div className="navBottomBanner">
                <img src={"/MineRehabilitation.jpg"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? ' 礦山修復' : (localStorage.language === 'en' ? 'Mine Rehabilitation' : '矿山修复')}</h1>
            </div>

            <div style={{height: '300px'}} className="MineRehabilitationContent">
                <Empty />
            </div>
        </div>
    );
}
export default MineRehabilitation;
