import React, {useEffect, useState } from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import '../public.css';
import {Table, Tooltip} from "antd";
import axios from "axios";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import './Company.css';
import {FilePdfOutlined} from "@ant-design/icons";


function Company () {
    interface DataType {
        key: string;
        title_simplified: string;
        title_traditional: string;
        title_english: string;
        article_simplified: string;
        article_traditional: string;
        article_english: string;
        type: string;
        author: string;
        pushDate: string;
        // tags: string[];
    }
    interface TableParams {
        pagination?: TablePaginationConfig;
        sortField?: string;
        sortOrder?: string;
        filters?: Record<string, FilterValue>;
    }

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 20,
        },
    })

    const fetchData = () => {
        setLoading(true);
        axios.get(`https://systemapi.grandtg.com/sapi/getCompanyAnnouncements?language=${localStorage.language}`).then((res) => {
            console.log(res.data)
            // console.log(res.data.pushDate)
            setData(res.data);
            setLoading(false);
            setTableParams({
                ...tableParams,
            })
        })
    }

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<DataType>,
    ) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    }


    const columns: ColumnsType<DataType> = [
        {
            title: `${localStorage.language === 'hk' ? '標題' : (localStorage.language === 'en' ? 'File title' : '标题')}`,
            dataIndex: `${localStorage.language === 'hk' ? 'title_traditional' : (localStorage.language === 'en' ? 'title_english' : 'title_simplified')}`,
            key: `${localStorage.language === 'hk' ? 'title_traditional' : (localStorage.language === 'en' ? 'title_english' : 'title_simplified')}`,
            ellipsis: true,
            render: (value, record) => {
                return (
                    <div title={value} style={{ whiteSpace: 'nowrap', float: 'left', /*maxWidth: '100%',*/ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Tooltip placement="topLeft" title={value}>
                            <a className={"article_title"} title={""} target="_blank" href={localStorage.language === 'hk' ? `${record.article_traditional}` : (localStorage.language === 'en' ? `${record.article_english}` : `${record.article_simplified}`)}>{value}</a>
                        </Tooltip>
                    </div>
                )
            }, // TODO 点击进入编辑界面
        },
        {
            title: `${localStorage.language === 'hk' ? '公開日期' : (localStorage.language === 'en' ? 'Publish Dates' : '公开日期')}`,
            dataIndex: 'pushDate',
            key: 'pushDate',
            width: window.navigator.userAgent.toString().indexOf('Mobile') !== -1 ? 90 : 120,
        },
        {
            title: `${localStorage.language === 'hk' ? '下載' : (localStorage.language === 'en' ? 'Download' : '下载')}`,
            dataIndex: `${localStorage.language === 'hk' ? 'article_traditional' : (localStorage.language === 'en' ? 'article_english' : 'article_simplified')}`,
            key: `${localStorage.language === 'hk' ? 'article_traditional' : (localStorage.language === 'en' ? 'article_english' : 'article_simplified')}`,
            render: (value, record) => {
                return (
                    <a style={{ color: '#E0AF3A' }} href={value}>
                        <FilePdfOutlined />
                    </a>
                )
            },
            width: 60,
        }
    ];

    return(
        <div className="ReportsContainer">
            <div className="navBottomBanner">
                <img className={"companyBanner"} style={{height: '500px'}} src={"/CompanyAnnouncements.jpg"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? ' 公司公告' : (localStorage.language === 'en' ? 'COMPANY ANNOUNCEMENTS' : '公司公告')}</h1>
            </div>
            <div className="ReportsContent">
                <Table dataSource={data} columns={columns} />
            </div>
        </div>
    );
}

export default Company;
